import { useEffect, useMemo, useState } from "react";
import {
  IoPauseCircleOutline,
  IoPlayCircleOutline,
  IoDownloadOutline,
} from "react-icons/io5";
import {
  ACTIVE_COLOR,
  PRIMARY_COLOR,
  PRIMARY_COLOR_FADED,
} from "../data/colors";
import { Chord, HarmonyNote } from "../types";
import { getChordOnQOrThrow, isChordOnQ } from "./useTimeLoop";

export function ChordSymbols({
  isPlaying,
  keyStr,
  chords,
  loading,
  endTimeLoop,
  startTimeLoop,
  chordsMidiFilePath,
  logEvent,
  onChordSymbolClicked,
  chordOnIdx,
  harmonyNotes,
}: {
  isPlaying: boolean;
  keyStr: string;
  chords: Chord[];
  loading: boolean;
  endTimeLoop: () => void;
  startTimeLoop: () => void;
  chordsMidiFilePath: string;
  logEvent: ({ eventName }: { eventName: string }) => void;
  onChordSymbolClicked: ({ chord, idx }: { chord: Chord; idx: number }) => void;
  chordOnIdx: number;
  harmonyNotes: HarmonyNote[];
}) {
  const keyToRender = useMemo(() => {
    return keyStr.replace("flat", "b").replace("sharp", "#");
  }, [keyStr]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "center",
        gap: 24,
        flex: 1,
      }}
    >
      {/* {!loading && (
        <div
          style={{
            color: "#B0B0B0",
          }}
        >
          <b>{keyToRender}</b>
        </div>
      )} */}

      {loading ? (
        <LoadingNewChords />
      ) : (
        [
          [0, 2],
          [4, 6],
          [8, 10],
          [12, 14],
        ].map((qGroup) => (
          <div
            key={qGroup[0]}
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              gap: 8,
            }}
          >
            {isChordOnQ({ q: qGroup[0], harmonyNotes }) && (
              <ChordSymbol
                chordOnIdx={chordOnIdx}
                chords={chords}
                q={qGroup[0]}
                onChordSymbolClicked={onChordSymbolClicked}
              />
            )}
            {isChordOnQ({ q: qGroup[1], harmonyNotes }) && (
              <ChordSymbol
                chordOnIdx={chordOnIdx}
                chords={chords}
                q={qGroup[1]}
                onChordSymbolClicked={onChordSymbolClicked}
              />
            )}
          </div>
        ))
      )}

      {/* {chords.length > 0 &&
        !loading &&
        chords.map((chord, idx) => { */}
    </div>
  );
}

function LoadingNewChords() {
  const [dots, setDots] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prevDots) => (prevDots % 3) + 1); // Cycle dots from 1 to 3
    }, 500);

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, []);

  return (
    <div style={{ textAlign: "left", width: 320, fontSize: 26 }}>
      Loading new chords{".".repeat(dots)}
    </div>
  );
}

function ChordSymbol({
  chordOnIdx,
  chords,
  q,
  onChordSymbolClicked,
}: {
  chordOnIdx: number;
  chords: Chord[];
  q: number;
  onChordSymbolClicked: ({ chord, idx }: { chord: Chord; idx: number }) => void;
}) {
  const chord: Chord = useMemo(() => {
    return getChordOnQOrThrow({ chords, q });
  }, [chords, q]);
  const isChordActive = chords.indexOf(chord) === chordOnIdx;

  let color = isChordActive ? ACTIVE_COLOR : "";
  const backgroundColor = isChordActive ? PRIMARY_COLOR_FADED : "";

  const { cursor, className } = {
    cursor: "pointer",
    className: "icon",
  };

  if (chords.indexOf(chord) === -1) {
    window.alert("CHORD NOT FOUND FOR SYMBOL CLICKED");
  }

  return (
    <div
      key={`${chord.measureNumber}${chord.beatNumber}`}
      style={{
        color,
        cursor,
        backgroundColor,
        width: 72,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: 24,
        flex: 1,
        padding: `0 24px`,
      }}
      className={`${className} box`}
      onClick={() =>
        onChordSymbolClicked({ chord, idx: chords.indexOf(chord) })
      }
    >
      {/* {chord.symbol.replace("maj", "▵")} */}
      {chord.symbol
        .replace("maj7", "")
        .replace("-7", "m")
        .replace("b", "♭")
        .replace("#", "♯")}
    </div>
  );
}
