import {
  ACTIVE_COLOR,
  PIANO_CHORD_KEYS,
  BAD_COLOR,
  COLOR_1,
} from "../data/colors";
import { ActiveHarmony, NoteWithOctave } from "../types";
import { MidiInput } from "./MidiInput";
import Piano from "./Piano";
import * as Tone from "tone";

export const STARTING_OCTAVE = 2;

export function LeadStuff({
  activeHarmony,
  leadSynth,
  pressedNotes,
  handleKeyClick,
  handleKeyClickRelease,
  handleKeyPress,
  handleKeyRelease,
  logEvent,
}: {
  activeHarmony: ActiveHarmony;
  leadSynth: Tone.Sampler;
  pressedNotes: NoteWithOctave[];
  handleKeyClick: ({ key }: { key: string }) => void;
  handleKeyClickRelease: ({ key }: { key: string }) => void;
  handleKeyPress: ({
    noteWithOctave,
  }: {
    noteWithOctave: NoteWithOctave;
  }) => void;
  handleKeyRelease: ({
    noteWithOctave,
  }: {
    noteWithOctave: NoteWithOctave;
  }) => void;
  logEvent: ({ eventName }: { eventName: string }) => void;
}) {
  return (
    <div
      className="box main-button joyride-piano"
      style={{ flexDirection: "column", gap: 24 }}
    >
      <div
        className="shadow fade-in"
        style={{ borderRadius: "24px", maxWidth: 754 }}
      >
        <Piano
          activeColorSlightlyDarker={PIANO_CHORD_KEYS}
          activeHarmony={activeHarmony}
          synth={leadSynth}
          correctColor={COLOR_1}
          incorrectColor={BAD_COLOR}
          activeColor={ACTIVE_COLOR}
          pressedNotes={pressedNotes}
          handleKeyClick={handleKeyClick}
          handleKeyClickRelease={handleKeyClickRelease}
          startingOctave={STARTING_OCTAVE}
        />
      </div>
      <MidiInput
        scaleNoteMapForMIDIInput={activeHarmony.scaleNoteMapForMIDIInput}
        handleKeyPress={handleKeyPress}
        handleKeyRelease={handleKeyRelease}
        logEvent={logEvent}
      />
    </div>
  );
}
