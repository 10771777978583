import React, { useState, useEffect } from "react";
import { Stage, Layer, Rect, Group } from "react-konva";
import { getEnharmonicFlat } from "../utils/theory";
import * as Tone from "tone";
import {
  ActiveHarmony,
  Note,
  NoteName,
  NoteWithOctave,
  noteWithOctaveFromStr,
} from "../types";

const noteNames = [
  "C",
  "Db",
  "D",
  "Eb",
  "E",
  "F",
  "Gb",
  "G",
  "Ab",
  "A",
  "Bb",
  "B",
];
const octaves = 4;

const whiteWidth = 27;
const blackWidth = 18;
const whiteHeight = 120;
const blackHeight = 80;

const blackToWhiteWidthRatio = blackWidth / whiteWidth;
const yDelta = 0;

const Piano = ({
  activeColorSlightlyDarker,
  synth,
  correctColor,
  incorrectColor,
  activeColor,
  pressedNotes,
  handleKeyClick,
  handleKeyClickRelease,
  startingOctave,
  activeHarmony,
}: {
  activeColorSlightlyDarker: string;
  synth: Tone.Sampler;
  correctColor: string;
  incorrectColor: string;
  activeColor: string;
  pressedNotes: NoteWithOctave[];
  handleKeyClick: ({ key }: { key: string }) => void;
  handleKeyClickRelease: ({ key }: { key: string }) => void;
  startingOctave: number;
  activeHarmony: ActiveHarmony;
}) => {
  const [fullNotes, setFullNotes] = useState<NoteWithOctave[]>([]);

  useEffect(() => {
    // Initialize full notes
    const generatedNotes: NoteWithOctave[] = [];
    for (let o = 0; o < octaves; o++) {
      for (let noteName of noteNames) {
        generatedNotes.push({
          name: noteName,
          octave: startingOctave + o,
        });
      }
    }
    setFullNotes(generatedNotes);
  }, [startingOctave]);

  const isNotePressed = ({ note }: { note: NoteWithOctave }) => {
    return (
      pressedNotes.filter((item) =>
        sameNoteAndOctave({
          note1: item,
          note2: { ...note, octave: note.octave + startingOctave },
        })
      ).length > 0
    );
  };

  const isPrimaryScaleNote = ({ note }: { note: NoteWithOctave }) => {
    return (
      activeHarmony.scaleNotes.filter((item) =>
        sameNote({ note1: item, note2: note.name })
      ).length > 0
    );
  };

  const isActiveHarmonyNote = ({ note }: { note: NoteWithOctave }) => {
    return (
      [...activeHarmony.activeHarmonyNotes].filter((item) =>
        sameNoteAndOctave({
          note1: noteWithOctaveFromStr(item.note),
          note2: { ...note, octave: note.octave + startingOctave },
        })
      ).length > 0
    );
  };

  const sameNoteAndOctave = ({
    note1,
    note2,
  }: {
    note1: NoteWithOctave;
    note2: NoteWithOctave;
  }) => {
    return (
      sameNote({ note1: note1.name, note2: note2.name }) &&
      note1.octave === note2.octave
    );
  };

  const sameNote = ({ note1, note2 }: { note1: NoteName; note2: NoteName }) => {
    const note1note = getEnharmonicFlat({ note: note1 });
    const note2note = getEnharmonicFlat({ note: note2 });

    return note1note === note2note;
  };

  const getKeyColor = ({
    note,
    black,
  }: {
    note: NoteWithOctave;
    black?: boolean;
  }) => {
    if (isNotePressed({ note })) {
      if (
        isPrimaryScaleNote({ note }) ||
        activeHarmony.scaleNotes.length === 0
      ) {
        return correctColor;
      } else {
        return incorrectColor;
      }
    } else if (isActiveHarmonyNote({ note })) {
      return activeColorSlightlyDarker;
    } else if (isPrimaryScaleNote({ note })) {
      return activeColor;
    } else if (black) {
      return "#000000";
    } else {
      return "#ffffff";
    }
  };

  const renderKey = ({ note }: { note: NoteWithOctave }) => {
    switch (note.name) {
      case "C":
        return renderLeftWhiteNote({ note, left: 7 * note.octave });
      case "Db":
        return renderBlackNote({
          note,
          left: 7 * note.octave + blackToWhiteWidthRatio,
        });
      case "D":
        return renderMiddleWhiteNote({ note, left: 7 * note.octave + 1 });
      case "Eb":
        return renderBlackNote({
          note,
          left: 7 * note.octave + 1 + blackToWhiteWidthRatio,
        });
      case "E":
        return renderRightWhiteNote({ note, left: 7 * note.octave + 2 });
      case "F":
        return renderLeftWhiteNote({ note, left: 7 * note.octave + 3 });
      case "Gb":
        return renderBlackNote({
          note,
          left: 7 * note.octave + 3 + blackToWhiteWidthRatio,
        });
      case "G":
        return renderMiddleWhiteNote({ note, left: 7 * note.octave + 4 });
      case "Ab":
        return renderBlackNote({
          note,
          left: 7 * note.octave + 4 + blackToWhiteWidthRatio,
        });
      case "A":
        return renderMiddleWhiteNote({ note, left: 7 * note.octave + 5 });
      case "Bb":
        return renderBlackNote({
          note,
          left: 7 * note.octave + 5 + blackToWhiteWidthRatio,
        });
      case "B":
        return renderRightWhiteNote({ note, left: 7 * note.octave + 6 });
    }
  };

  const renderLeftWhiteNote = ({
    note,
    left,
    text,
  }: {
    note: NoteWithOctave;
    left: number;
    text?: string;
  }) => (
    <Group>
      <Rect
        x={left * whiteWidth}
        y={yDelta}
        width={whiteWidth - blackWidth / 2}
        height={blackHeight + 2}
        fill={getKeyColor({ note })}
      />
      <Rect
        x={left * whiteWidth}
        y={yDelta + blackHeight + 1}
        width={whiteWidth}
        height={whiteHeight - blackHeight - 1}
        fill={getKeyColor({ note })}
      />
      <Rect
        x={left * whiteWidth + whiteWidth - 2}
        y={yDelta + blackHeight}
        width={2}
        height={whiteHeight - blackHeight}
        fill="black"
      />
    </Group>
  );

  const renderMiddleWhiteNote = ({
    note,
    left,
    text,
  }: {
    note: NoteWithOctave;
    left: number;
    text?: string;
  }) => {
    return (
      <Group>
        <Rect
          x={left * whiteWidth + blackWidth / 2}
          y={yDelta}
          width={whiteWidth - blackWidth}
          height={blackHeight + 2}
          fill={getKeyColor({ note })}
        />
        <Rect
          x={left * whiteWidth}
          y={yDelta + blackHeight + 1}
          width={whiteWidth}
          height={whiteHeight - blackHeight - 1}
          fill={getKeyColor({ note })}
        />
        <Rect
          x={left * whiteWidth + whiteWidth - 2}
          y={yDelta + blackHeight}
          width={2}
          height={whiteHeight - blackHeight}
          fill="black"
        />
      </Group>
    );
  };

  const renderRightWhiteNote = ({
    note,
    left,
    text,
  }: {
    note: NoteWithOctave;
    left: number;
    text?: string;
  }) => {
    return (
      <Group>
        <Rect
          x={left * whiteWidth + blackWidth / 2}
          y={yDelta}
          width={whiteWidth - blackWidth / 2}
          height={blackHeight + 2}
          fill={getKeyColor({ note })}
        />
        <Rect
          x={left * whiteWidth}
          y={yDelta + blackHeight + 1}
          width={whiteWidth}
          height={whiteHeight - blackHeight - 1}
          fill={getKeyColor({ note })}
        />
        <Rect
          x={left * whiteWidth + whiteWidth - 2}
          y={yDelta}
          width={2}
          height={whiteHeight}
          fill="black"
        />
      </Group>
    );
  };

  const renderPureWhiteNote = ({
    note,
    left,
    text,
  }: {
    note: NoteWithOctave;
    left: number;
    text?: string;
  }) => {
    return (
      <Group>
        <Rect
          x={left * whiteWidth}
          y={yDelta}
          width={whiteWidth - 2}
          height={whiteHeight}
          fill={getKeyColor({ note })}
        />
      </Group>
    );
  };

  const renderBlackNote = ({
    note,
    left,
    text,
  }: {
    note: NoteWithOctave;
    left: number;
    text?: string;
  }) => {
    return (
      <Group>
        <Rect
          x={left * whiteWidth + 1}
          y={yDelta}
          width={blackWidth - 2}
          height={blackHeight}
          fill={getKeyColor({ note, black: true })}
          stroke="black"
          strokeWidth={2}
        />
      </Group>
    );
  };

  return (
    <div>
      <Stage
        style={{ borderRadius: "8px", overflow: "hidden" }}
        width={whiteWidth * octaves * 7 - 2}
        height={whiteHeight}
      >
        <Layer>
          {fullNotes.map((note) =>
            renderKey({
              note: { ...note, octave: note.octave - startingOctave },
            })
          )}
        </Layer>
      </Stage>
    </div>
  );
};

export default Piano;
