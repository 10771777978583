import { HarmonyNote } from "../types";
import MidiWriter from "midi-writer-js";

const NOTES_IN_BAR = 4;

const TEMPO = 120;

export function refreshChordsMIDITrack({
  barsInLoop,
  harmonyNotes,
  bassNotes,
  setChordsMidiFilePath,
}: {
  barsInLoop: number;
  harmonyNotes: HarmonyNote[];
  bassNotes: HarmonyNote[];
  setChordsMidiFilePath: (path: string) => void;
}) {
  // // write MIDI file for download

  // Add some notes:
  const timeToNoteMap = [];
  for (let i = 0; i < barsInLoop * NOTES_IN_BAR; i++) {
    timeToNoteMap.push({ notes: [] });
  }

  addNotesToTimeToNoteMap({ notes: harmonyNotes, timeToNoteMap });
  addNotesToTimeToNoteMap({ notes: bassNotes, timeToNoteMap });

  const numberOfTracks = timeToNoteMap[0].notes.length;
  const tracks: any[] = [];
  for (let i = 0; i < numberOfTracks; i++) {
    tracks.push(new MidiWriter.Track());
  }
  for (let i = 0; i < numberOfTracks; i++) {
    const track = tracks[i];
    track.addEvent(new MidiWriter.ProgramChangeEvent({ instrument: 1 }));
  }

  addNotesToMIDITracks({ timeToNoteMap, tracks, barsInLoop });

  for (let i = 1; i < numberOfTracks; i++) {
    tracks[0].mergeTrack(tracks[i]);
  }

  tracks[0].setTempo(TEMPO);

  // Generate a data URI
  var write = new MidiWriter.Writer(tracks[0]);
  setChordsMidiFilePath(write.dataUri());
}

function addNotesToTimeToNoteMap({
  notes,
  timeToNoteMap,
}: {
  notes: HarmonyNote[];
  timeToNoteMap: any;
}) {
  for (let i = 0; i < notes.length; i++) {
    const note = notes[i];
    const noteCount = NOTES_IN_BAR * note.measureNumber + note.deltaFromMeasure;
    timeToNoteMap[noteCount]["notes"].push(note);
  }
}

function addNotesToMIDITracks({
  timeToNoteMap,
  tracks,
  barsInLoop,
}: {
  timeToNoteMap: any;
  tracks: any[];
  barsInLoop: number;
}) {
  for (let i = 0; i < barsInLoop * NOTES_IN_BAR; i++) {
    let notes = timeToNoteMap[i].notes;

    for (let n = 0; n < notes.length; n++) {
      let note = notes[n];

      let duration;
      if (note.length == 1) {
        duration = "4";
      } else if (note.length == 2) {
        duration = "2";
      } else if (note.length == 3) {
        duration = "d2"; // https://www.npmjs.com/package/midi-writer-js for explanation (d2 = dotted half)
      } else if (note.length == 4) {
        duration = "1";
      } else if (note.length == 0.5) {
        duration = "8";
      } else if (note.length == 0.25) {
        duration = "16";
      }
      if (note.length > 0) {
        // note.length == 0 => this is a rest, not a note
        let midiNote = new MidiWriter.NoteEvent({
          pitch: note.note,
          duration,
          velocity: note.velocity,
        });
        tracks[n].addEvent(midiNote);
      }
    }
  }
}
