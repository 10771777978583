import { IoPauseCircleOutline, IoPlayCircleOutline } from "react-icons/io5";

export function PlayPauseButton({
  isPlaying,
  logEvent,
  loading,
  stopLoop,
  playLoop,
}: {
  isPlaying: boolean;
  logEvent: ({ eventName }: { eventName: string }) => void;
  loading: boolean;
  stopLoop: () => void;
  playLoop: () => void;
}) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifySelf: "flex-end",
        alignItems: "center",
      }}
    >
      {!isPlaying ? (
        <div
          className="active clickable icon"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
          onClick={() => {
            logEvent({
              eventName: "play clicked",
            });

            if (!loading) {
              stopLoop();
              playLoop();
            }
          }}
        >
          <IoPlayCircleOutline size={100} />
        </div>
      ) : (
        <div
          className="poopy icon"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            cursor: "pointer",
          }}
          onClick={() => {
            logEvent({
              eventName: "pause clicked",
            });

            if (!loading) {
              stopLoop();
            }
          }}
        >
          <IoPauseCircleOutline size={100} />
        </div>
      )}
    </div>
  );
}
