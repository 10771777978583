import { useState } from "react";
import { IoVolumeMedium } from "react-icons/io5";
import Slider, { Range } from "rc-slider";
import {
  COLOR_1,
  DARKER_PRIMARY,
  PRIMARY_COLOR,
  PRIMARY_COLOR_FADED,
} from "../data/colors";

export function VolumeSetting({
  onVolumeChange,
  volume,
}: {
  volume: number;
  onVolumeChange: (volume: number) => void;
}) {
  const [isVolumeVisible, setIsVolumeVisible] = useState(false);

  return (
    <div style={{ display: "flex" }}>
      <div
        style={{
          display: "flex",
        }}
        onMouseEnter={() => setIsVolumeVisible(true)}
        onMouseLeave={() => setIsVolumeVisible(false)}
      >
        <div style={{ width: 120, marginTop: 6, marginRight: 20 }}>
          <Slider
            min={0}
            max={100}
            step={1}
            onChange={(value) => {
              onVolumeChange(value);
            }}
            value={volume}
            handleStyle={{
              backgroundColor: PRIMARY_COLOR,
              borderColor: DARKER_PRIMARY,
              height: 20,
              width: 20,
              borderRadius: 10,
              marginTop: -6,
              marginBottom: 3,
              borderWidth: 1.5,
            }}
            trackStyle={{
              backgroundColor: PRIMARY_COLOR,
              height: 8,
              width: 120,
              borderRadius: 5,
            }}
            railStyle={{
              height: 8,
              borderRadius: 5,
            }}
          />
        </div>
        <div
          style={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
          }}
        >
          <IoVolumeMedium size={30} />
        </div>
      </div>
    </div>
  );
}
