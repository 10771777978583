import { IoHelp, IoHelpCircleOutline } from "react-icons/io5";
import { COLOR_1 } from "../data/colors";

export function Header({ onHelpClicked }: { onHelpClicked: () => void }) {
  return (
    <div
      className="App-header "
      style={{
        display: "flex",
        justifyContent: "space-between",
        fontSize: 20,
        paddingLeft: 32,
        paddingRight: 32,
        alignItems: "center",
        height: 70,
        backgroundColor: "#181825",
        borderBottom: "1px solid black",
      }}
    >
      <div className="neonText">
        <b>Harmonia</b> - the musical instrument of your dreams.
      </div>

      <div className="icon clickable" onClick={onHelpClicked}>
        <IoHelpCircleOutline size={32} />
      </div>
    </div>
  );
}
