import { NOTE_ARR } from "../data/theory";
import { NoteName } from "../types";

export function getEnharmonicFlat({ note }: { note: NoteName }) {
  switch (note) {
    case "C#":
      return "Db";
    case "D#":
      return "Eb";
    case "F#":
      return "Gb";
    case "G#":
      return "Ab";
    case "A#":
      return "Bb";
    default:
      return note;
  }
}

export function getOffset({ scaleNotes }: { scaleNotes: NoteName[] }): number {
  const flatScaleNotes = scaleNotes.map((n) => getEnharmonicFlat({ note: n }));
  for (let note of [
    "C",
    "Db",
    "D",
    "Eb",
    "E",
    "F",
    "Gb",
    "G",
    "Ab",
    "A",
    "Bb",
    "B",
  ]) {
    if (flatScaleNotes.includes(note)) {
      return flatScaleNotes.indexOf(note);
    }
  }

  throw new Error("Could not find offset");
}

export function getFirstNoteAboveC({ notes }: { notes: NoteName[] }) {
  for (const note of NOTE_ARR) {
    if (notes.includes(note)) {
      return note;
    }
  }
}
