import { NoteName, NoteWithOctave, ScaleNoteMap } from "../types";

export const KEY_NAME_TO_NUMBER_MAP: Record<string, number> = {
  1: 49,
  2: 50,
  3: 51,
  4: 52,
  5: 53,
  6: 54,

  Q: 81,
  W: 87,
  E: 69,
  R: 82,
  T: 84,
  Y: 89,
  U: 85,
  I: 73,
  O: 79,
  P: 80,

  A: 65,
  S: 83,
  D: 68,
  F: 70,
  G: 71,
  H: 72,
  J: 74,
  K: 75,
  L: 76,

  Z: 90,
  X: 88,
  C: 67,
  V: 86,
  B: 66,
  N: 78,
  M: 77,

  ";": 186,
  "'": 222,
};

export const KEY_NUMBER_TO_NOTE_MAP: Record<number, NoteName | "space"> = {
  65: "C4",
  87: "Db4",
  83: "D4",
  69: "Eb4",
  68: "E4",
  70: "F4",
  84: "Gb4",
  71: "G4",
  89: "Ab4",
  72: "A4",
  85: "Bb4",
  74: "B4",
  75: "C5",
  79: "Db5",
  76: "D5",
  80: "Eb5",
  186: "E5",
  222: "F5",
  32: "space",
};

export const KEY_NOTES_4_ROWS = [
  90, 88, 67, 86, 66, 78, 77, 65, 83, 68, 70, 71, 72, 81, 87, 69, 82, 84, 89,
  49, 50, 51, 52, 53, 54,
];

export const KEY_NOTES_A_Z = [
  90, 88, 67, 86, 66, 78, 77, 65, 83, 68, 70, 71, 72, 74, 75, 76, 81, 87, 69,
  82, 84, 89, 85, 73, 79, 80,
];

// export const KEY_NOTES_2_ROWS = [
//   87, 69, 82, 84, 89, 85, 73, 79, 80, 219, 221, 220,

//   65, 83, 68, 70, 71, 72, 74, 75, 76, 186, 222, 13,
// ];

function getKeyByValue(
  object: ScaleNoteMap,
  value: NoteWithOctave
): number | undefined {
  return Number(
    Object.keys(object).find(
      (key) =>
        object[Number(key)].name === value.name &&
        object[Number(key)].octave === value.octave
    )
  );
}

export function noteHasDifferentTriggerKey({
  note,
  prev,
  curr,
}: {
  note: NoteWithOctave;
  prev: ScaleNoteMap;
  curr: ScaleNoteMap;
}): boolean {
  const key1 = getKeyByValue(prev, note);
  const key2 = getKeyByValue(curr, note);

  return key1 !== key2;
}

export function getTriggerKey({
  note,
  obj,
}: {
  note: NoteWithOctave;
  obj: ScaleNoteMap;
}): number | undefined {
  return getKeyByValue(obj, note);
}
