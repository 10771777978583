export type NoteName = string; // "Ab"

export type NoteOctave = number;

export type NoteOctaveString = string; // A4

export type NoteWithOctave = {
  name: NoteName;
  octave: NoteOctave;
};

export type Note = {
  length: NoteLength;
  velocity: number;
  note: NoteOctaveString;
};

export type NoteLength = 1 | 2 | 3 | 4;
export type BeatNumber = 1 | 2 | 3 | 4;
export type ScaleNote = Note;

export type ScaleName =
  | "Major"
  | "Ionian"
  | "Dorian"
  | "Phrygian"
  | "Lydian"
  | "LydianFlat7"
  | "Mixolydian"
  | "V7Minor"
  | "Minor"
  | "Aeolian"
  | "Locrian"
  | "MelodicMinor"
  | "Altered"
  | "HalfWhole"
  | "WholeHalf"
  | "DiatonicDominantVofII";

export type HarmonyNote = {
  measureNumber: number;
  deltaFromMeasure: number;
  velocity: number;
  length: NoteLength;
} & Note;

export type HarmonyNoteSetForChord = {
  complexity: number;
  closedOrOpen: ClosedOrOpen;
  notes: HarmonyNote[];
};

export type Chord = {
  ID: string;
  scaleNotes: NoteName[];
  scale: ScaleName;
  harmonyNotes: HarmonyNoteSetForChord[];
  bassNote: HarmonyNote;
  measureNumber: number;
  beatNumber: BeatNumber;
  symbol: string;
  lengthInBeats: NoteLength;
};

export type AlternateChordSetType =
  | "minorTwoFive"
  | "modalInterchange"
  | "secondaryDominant";

export type AlternateChordType =
  | "modalInterchange"
  | "secondaryDominant"
  | "TwoInMinor25"
  | "FiveInMinor25";

export type DependentChord = Chord & {
  chordType: AlternateChordSetType;
  resolvesToID: string;
};

export type DependentChordSet = {
  chordType: AlternateChordSetType;
  resolvesToID: string;
  chords?: DependentChord[];
} & DependentChord;

export type ModalInterchange = Chord;
export type BaseChord = Chord;

export type HarmonyStateChord = BaseChord | DependentChord;

export type HarmonyState = {
  key: string;

  bassNotes: HarmonyNote[];
  harmonyNotes: HarmonyNote[];

  chords: HarmonyStateChord[];
  dependentChords: Record<AlternateChordSetType, DependentChordSet[]>;
  baseChords: BaseChord[];

  resolvesToIds: string[];
};

export type ClosedOrOpen = "closed" | "open";

export type LoopSettings = {
  numberOfBars: string;
  voicingComplexity: number;
  closedOrOpen: ClosedOrOpen;
  passingChordFrequency: number;

  majorTwoFive: boolean;
  minorTwoFive: boolean;
  diminished: boolean;
  modalInterchange: boolean;
  primaryDominant: boolean;
  secondaryDominant: boolean;
  substituteDominant: boolean;
};

export type ActiveHarmony = {
  scaleNotes: NoteName[];
  activeHarmonyNotes: Note[];

  lastScaleNoteMap: ScaleNoteMap;
  scaleNoteMap: ScaleNoteMap;

  lastScaleNoteMapForMIDIInput: MidiScaleNoteMap;
  scaleNoteMapForMIDIInput: MidiScaleNoteMap;
};

export type ScaleNoteMap = Record<number, NoteWithOctave>;
export type MidiScaleNoteMap = Record<NoteOctaveString, NoteWithOctave>;

export type LoopState = {
  isPlaying: boolean;
  chordOnIdx: number;
};

export function noteOctaveStr(note: NoteWithOctave): NoteOctaveString {
  return `${note.name}${note.octave}`;
}

export function noteWithOctaveFromStr(note: NoteOctaveString): NoteWithOctave {
  return {
    name: note.slice(0, -1),
    octave: parseInt(note.slice(-1)),
  };
}

export function sameNoteWithOctave({
  note1,
  note2,
}: {
  note1: NoteWithOctave;
  note2: NoteWithOctave;
}): boolean {
  return note1.name === note2.name && note1.octave === note2.octave;
}
