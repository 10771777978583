import * as Tone from "tone";

export const DRUM_NOTE = "C1";

export function generateNewSynths() {
  const padSynth = new Tone.Sampler({
    urls: {
      C5: "C4.wav",
    },
    baseUrl: process.env.PUBLIC_URL + "/assets/bass-synth/",
  }).toDestination();
  padSynth.volume.value = -14;

  let hp_filter = new Tone.Filter(2000, "highpass", -96).toDestination();
  let lp_filter_pad = new Tone.Filter(2200, "lowpass", -24).toDestination();
  let lp_filter_lead = new Tone.Filter(4000, "lowpass", -12).toDestination();
  padSynth.connect(lp_filter_pad);
  //padSynth.connect(hp_filter);

  const bassSynth = new Tone.Sampler({
    urls: {
      C5: "C4.wav",
    },
    baseUrl: process.env.PUBLIC_URL + "/assets/bass-synth/",
  }).toDestination();
  bassSynth.volume.value = -12;
  let lp_filter_bass = new Tone.Filter(1500, "lowpass", -96).toDestination();

  bassSynth.connect(lp_filter_bass);

  const drumsSampler = new Tone.Sampler({
    urls: {
      [DRUM_NOTE]: "1.wav",
    },
    baseUrl: process.env.PUBLIC_URL + "/assets/drums/",
  }).toDestination();
  drumsSampler.volume.value = -8;

  const leadSynth = new Tone.Sampler({
    urls: {
      C5: "C4.wav",
    },
    baseUrl: process.env.PUBLIC_URL + "/assets/bass-synth/",
  }).toDestination();
  leadSynth.volume.value = 6;

  return {
    padSynth,
    leadSynth,
    bassSynth,
    drumsSampler,
  };
}
