import { IoMusicalNotes, IoReloadCircleOutline } from "react-icons/io5";
import { HarmonyState, HarmonyStateChord, LoopSettings } from "../types";
import { ChordSymbols } from "./ChordSymbols";
import { ComplexitySettings } from "./ComplexitySettings";
import { DownloadChordsButton } from "./DownloadChordsButton";
import { NewChords, UserInputChord } from "./NewChords";
import { ClipLoader } from "react-spinners";
import { NewSynthButton } from "./NewSynthButton";
import { NewChordsButton } from "./NewChordsButton";

export function ChordStuff({
  harmonyState,
  loopState,
  loading,
  stopLoop,
  playLoop,
  chordsMidiFilePath,
  logEvent,
  onChordSymbolClicked,
  fetchNewLoop,
  settings,
  setSettings,
  userInputChords,
  setUserInputChords,
  handleNewPadSynth,
  onNewMediumLevelComplexity,
}: {
  harmonyState: HarmonyState;
  loopState: { isPlaying: boolean; chordOnIdx: number };
  loading: boolean;
  stopLoop: () => void;
  playLoop: () => void;
  chordsMidiFilePath: string;
  logEvent: ({
    eventName,
  }: {
    eventName: string;
    details?: Record<string, string>;
  }) => void;
  onChordSymbolClicked: ({ chord, idx }: { chord: any; idx: number }) => void;
  fetchNewLoop: (settings: LoopSettings) => void;
  settings: LoopSettings;
  setSettings: React.Dispatch<React.SetStateAction<LoopSettings>>;
  userInputChords: UserInputChord[];
  setUserInputChords: (chords: UserInputChord[]) => void;
  handleNewPadSynth: () => void;
  onNewMediumLevelComplexity: ({ complexity }: { complexity: number }) => void;
}) {
  return (
    <div
      className="box"
      style={{
        flexDirection: "row",
        display: "flex",
        // alignItems: "center",
        gap: 32,
        width: 880,
        height: 120,
      }}
    >
      <ChordSymbols
        isPlaying={loopState.isPlaying}
        keyStr={harmonyState.key}
        chords={harmonyState.chords}
        loading={loading}
        endTimeLoop={stopLoop}
        startTimeLoop={playLoop}
        chordsMidiFilePath={chordsMidiFilePath}
        logEvent={logEvent}
        onChordSymbolClicked={onChordSymbolClicked}
        chordOnIdx={loopState.chordOnIdx}
        harmonyNotes={harmonyState.harmonyNotes}
      />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: 140,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            gap: 16,
            height: 100,
          }}
        >
          <DownloadChordsButton
            chordsMidiFilePath={chordsMidiFilePath}
            logEvent={logEvent}
          />

          {/* <NewSynthButton
            logEvent={logEvent}
            handleNewPadSynth={handleNewPadSynth}
          /> */}

          <NewChordsButton
            loading={loading}
            userInputChords={userInputChords}
            logEvent={logEvent}
            stopLoop={stopLoop}
            settings={settings}
            setSettings={setSettings}
            fetchNewLoop={fetchNewLoop}
          />
        </div>
        <ComplexitySettings
          onNewMediumLevelComplexity={onNewMediumLevelComplexity}
        />
      </div>

      {/* <NewChords
        barsInLoop={settings.numberOfBars}
        setBarsInLoop={(newBarsInLoop: string) =>
          setSettings((prev) => ({
            ...prev,
            numberOfBars: newBarsInLoop,
          }))
        }
        fetchNewLoop={fetchNewLoop}
        loading={loading}
        settings={settings}
        setSettings={setSettings}
        userInputChords={userInputChords}
        setUserInputChords={setUserInputChords}
        logEvent={logEvent}
        endTimeLoop={endTimeLoop}
        complexity={settings.voicingComplexity}
        randomizeLowLevelSettings={randomizeLowLevelSettings}
      /> */}
    </div>
  );
}
