import "./App.css";
import React from "react";
import "rc-slider/assets/index.css";

import { setUserId } from "firebase/analytics";

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { useLogger } from "./logger";
import { Harmonia } from "./components/Harmonia";

const firebaseConfig = {
  apiKey: "AIzaSyAavAHfruGPv9Q0TSh6UdNSWfqVg8rRhts",
  authDomain: "harmonia-14be2.firebaseapp.com",
  projectId: "harmonia-14be2",
  storageBucket: "harmonia-14be2.firebasestorage.app",
  messagingSenderId: "284737044998",
  appId: "1:284737044998:web:73cab50674963eb4acf987",
  measurementId: "G-5VRZSWH82E",
};
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function getOrCreateUserId() {
  let userId = localStorage.getItem("user_id");
  if (!userId) {
    userId = crypto.randomUUID(); // Generates a unique ID
    localStorage.setItem("user_id", userId);
  }
  return userId;
}

// Get or create a user ID
const userId = getOrCreateUserId();

setUserId(analytics, userId);

console.log("userId", userId);

function App() {
  const { logEvent } = useLogger({ analytics, userId });
  return (
    <div
      style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}
    >
      {/* <ToneLoopExample /> */}
      <Harmonia logEvent={logEvent} />
      {/* <Amadeus logEvent={logEvent} /> */}
    </div>
  );
}

export default App;
