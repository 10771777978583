import Slider, { Range } from "rc-slider";
import { useState } from "react";
import {
  COLOR_1,
  DARKER_PRIMARY,
  PRIMARY_COLOR,
  PRIMARY_COLOR_FADED,
} from "../data/colors";
import { randomChoice } from "../utils/arrays";

export function ComplexitySettings({
  onNewMediumLevelComplexity,
}: {
  onNewMediumLevelComplexity: ({ complexity }: { complexity: number }) => void;
}) {
  const [complexity, setComplexity] = useState(randomChoice([1, 2, 3]));

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        paddingBottom: 16,
      }}
      className="joyride-slider"
    >
      <Slider
        min={0}
        max={4}
        step={1}
        onChange={(value) => {
          setComplexity(value);
          onNewMediumLevelComplexity({ complexity: value });
        }}
        value={complexity}
        handleStyle={{
          backgroundColor: PRIMARY_COLOR,
          borderColor: DARKER_PRIMARY,
          height: 32,
          width: 32,
          borderRadius: 20,
          marginTop: -8,
          marginBottom: 6,
        }}
        trackStyle={{
          backgroundColor: PRIMARY_COLOR,
          height: 16,
          width: 220,
          borderRadius: 10,
        }}
        railStyle={{
          height: 16,
          borderRadius: 10,
        }}
      />
    </div>
  );
}
