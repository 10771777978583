import { useCallback, useEffect, useRef, useState } from "react";
import WebMidi from "webmidi";
import { getEnharmonicFlat } from "../utils/theory";
import Dropdown from "react-dropdown";
import { MidiScaleNoteMap, NoteName, NoteWithOctave } from "../types";

export function MidiInput({
  scaleNoteMapForMIDIInput,
  handleKeyPress,
  handleKeyRelease,
  logEvent,
}: {
  scaleNoteMapForMIDIInput: MidiScaleNoteMap;
  handleKeyPress: ({
    noteWithOctave,
  }: {
    noteWithOctave: NoteWithOctave;
  }) => void;
  handleKeyRelease: ({
    noteWithOctave,
  }: {
    noteWithOctave: NoteWithOctave;
  }) => void;
  logEvent: (event: {
    eventName: string;
    details: Record<string, string>;
  }) => void;
}) {
  const [activeMidiInput, setActiveMidiInput] = useState("none");
  const [midiInputs, setMidiInputs] = useState(["none"]);

  const scaleNoteMapRef = useRef(scaleNoteMapForMIDIInput);
  useEffect(() => {
    scaleNoteMapRef.current = scaleNoteMapForMIDIInput;
  }, [scaleNoteMapForMIDIInput]);

  const handleMidiInputDropdownNewVal = useCallback(
    ({ newVal }: { newVal: string }) => {
      if (newVal == "none") {
        let inputs = WebMidi.inputs;
        inputs.forEach((input) => {
          input.removeListener();
        });
        return;
      }
      // Retrieve an input by name, id or index
      var input = WebMidi.getInputByName(newVal);

      if (!input) {
        return;
      }

      // Listen for a 'note on' message on all channels
      input.addListener("noteon", "all", function (e) {
        console.log(
          "Received 'noteon' message (" + e.note.name + e.note.octave + ")."
        );
        logEvent({
          eventName: "midi note on",
          details: {
            note: `${e.note.name}${e.note.octave}`,
          },
        });

        const note = getEnharmonicFlat({ note: e.note.name }) + e.note.octave;
        console.log(note, scaleNoteMapRef.current);
        if (!scaleNoteMapRef.current[note]) {
          return;
        } else {
          handleKeyPress({ noteWithOctave: scaleNoteMapRef.current[note] });
        }
      });

      // Listen for a 'note off' message on all channels
      input.addListener("noteoff", "all", function (e) {
        console.log(
          "Received 'noteoff' message (" + e.note.name + e.note.octave + ")."
        );

        const note = getEnharmonicFlat({ note: e.note.name }) + e.note.octave;
        if (!scaleNoteMapRef.current[note]) {
          return;
        } else {
          handleKeyRelease({ noteWithOctave: scaleNoteMapRef.current[note] });
        }
      });
    },
    [handleKeyPress, handleKeyRelease, logEvent]
  );

  useEffect(() => {
    WebMidi.enable(function (err) {
      if (err) {
        console.log("web midi could not be enabled");
        return;
      }

      // Reacting when a new device becomes available
      WebMidi.addListener("connected", function (e) {
        const inputs = WebMidi.inputs;
        const names = inputs.map((input) => input.name);
        names.unshift("none");
        setMidiInputs(names);
        setActiveMidiInput(e.port.name);

        handleMidiInputDropdownNewVal({ newVal: e.port.name });
      });

      // Reacting when a device becomes unavailable
      WebMidi.addListener("disconnected", function (e) {
        // console.log(e);
        const inputs = WebMidi.inputs;
        const names = inputs.map((input) => input.name);
        setMidiInputs(names);
        if (!names.includes(activeMidiInput)) {
          setActiveMidiInput("none");
        }
      });
    });
  }, [
    activeMidiInput,
    handleKeyPress,
    handleKeyRelease,
    logEvent,
    handleMidiInputDropdownNewVal,
  ]);

  return WebMidi.supported ? (
    <div
      className="cell"
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 8,
        marginBottom: 8,
        fontSize: 18,
      }}
    >
      <p>MIDI In</p>
      <Dropdown
        options={midiInputs}
        value={activeMidiInput}
        onChange={(changeEvent) => {
          setActiveMidiInput(changeEvent.value);
          handleMidiInputDropdownNewVal({
            newVal: changeEvent.value,
          });
        }}
        className="DropDown"
        controlClassName="ControlClass"
      />
    </div>
  ) : (
    <div className="cell" style={{ fontSize: 12, maxWidth: 150 }}>
      Switch to a new browser to use MIDI input!
    </div>
  );
}
