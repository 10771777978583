import { BACKGROUND_COLOR, TEXT_COLOR } from "../data/colors";

export function Button({
  text,
  onClick,
}: {
  text: string;
  onClick: () => void;
}) {
  return (
    <div
      className="box clickable icon"
      style={{ backgroundColor: BACKGROUND_COLOR, color: TEXT_COLOR }}
      onClick={onClick}
    >
      {text}
    </div>
  );
}
