import {
  AlternateChordSetType,
  AlternateChordType,
  Chord,
  DependentChordSet,
  HarmonyNote,
  HarmonyState,
  HarmonyStateChord,
  LoopSettings,
  ModalInterchange,
  NoteLength,
  ScaleName,
} from "../types";
import { shuffleArray } from "./arrays";

export function trimToSix({ chord }: { chord: Chord }) {
  if (chord.scaleNotes.length <= 6) {
    return chord.scaleNotes;
  }
  const scaleMap: Record<ScaleName, number | number[]> = {
    Major: 3,
    Ionian: 3,
    Dorian: 3,
    Phrygian: 1,
    // 'Phrygian': [1, 5],
    Lydian: 4,
    LydianFlat7: 4,
    Mixolydian: 3,
    V7Minor: [2, 4],
    Minor: 5,
    Aeolian: 5,
    Locrian: 5,
    MelodicMinor: 3,
    Altered: 5,
    HalfWhole: [2, 5],
    WholeHalf: [3, 5],
    DiatonicDominantVofII: 3,
  };

  const toRemove = scaleMap[chord.scale];
  const scale = JSON.parse(JSON.stringify(chord.scaleNotes)); // deep copy
  const newScale = [];

  for (let note of scale) {
    note = note.note;
    note = note.replace("flat", "b");
    note = note.replace("sharp", "#");
    newScale.push(note);
  }

  if (!toRemove) {
    return newScale;
  } else if (typeof toRemove === "number") {
    newScale.splice(toRemove, 1);
    return newScale;
  } else {
    for (let el of toRemove) {
      newScale.splice(el, 1);
    }
    return newScale;
  }
}

export function addAlternateChord({
  chordType,
  harmonyState,
  settings,
}: {
  chordType: AlternateChordSetType;
  harmonyState: HarmonyState;
  settings: LoopSettings;
}): HarmonyState {
  const { dependentChords } = harmonyState;

  if (chordType == "modalInterchange") {
    shuffleArray(dependentChords.modalInterchange);

    return tryModalInterchange({
      harmonyState,
      modalInterchange: dependentChords.modalInterchange,
      settings,
    });
  } else if (chordType == "minorTwoFive") {
    shuffleArray(dependentChords[chordType]);

    return tryTwoFive({
      chordType,
      harmonyState,
      dependentChords,
      settings,
    });
  } else {
    shuffleArray(dependentChords[chordType]);

    return tryDependentChord({
      chordType,
      harmonyState,
      settings,
    });
  }
}

function tryTwoFive({
  chordType,
  harmonyState,
  dependentChords,
  settings,
}: {
  chordType: AlternateChordSetType;
  harmonyState: HarmonyState;
  dependentChords: Record<AlternateChordSetType, DependentChordSet[]>;
  settings: LoopSettings;
}) {
  const { chords, resolvesToIds, baseChords } = harmonyState;

  for (let i = 0; i < dependentChords[chordType].length; i++) {
    let candidate = dependentChords[chordType][i];

    let two = candidate.chords?.[0];
    let five = candidate.chords?.[1];

    if (two == null || five == null) {
      throw new Error("two or five is null");
    }

    // retrieve chords to replace
    let chordTwoReplaces;
    for (let i2 = 0; i2 < chords.length; i2++) {
      const chord = chords[i2];
      if (
        chord.beatNumber == two.beatNumber &&
        chord.measureNumber == two.measureNumber
      ) {
        chordTwoReplaces = chord;
      }
    }

    let chordFiveReplaces;
    for (let i2 = 0; i2 < chords.length; i2++) {
      const chord = chords[i2];
      if (
        chord.beatNumber == five.beatNumber &&
        chord.measureNumber == five.measureNumber
      ) {
        chordFiveReplaces = chord;
      }
    }

    // check if resolvesToID is in activeIDs and NOT in resolvedToIDs (i.e. another chord already resolves there)...
    // also make sure this chord will not replace a chord in resolvedToIDs and it is replacing a base chord

    let twoReplacementOkay = true;
    if (!!chordTwoReplaces) {
      twoReplacementOkay =
        !resolvesToIds.includes(chordTwoReplaces.ID) &&
        baseChords
          .map((baseChord) => baseChord.ID)
          .includes(chordTwoReplaces.ID);
    }

    let fiveReplacementOkay = true;
    if (!!chordFiveReplaces) {
      fiveReplacementOkay =
        !resolvesToIds.includes(chordFiveReplaces.ID) &&
        baseChords
          .map((baseChord) => baseChord.ID)
          .includes(chordFiveReplaces.ID);
    }

    if (
      harmonyState.chords
        .map((chord) => chord.ID)
        .includes(candidate.resolvesToID) &&
      !resolvesToIds.includes(candidate.resolvesToID) &&
      twoReplacementOkay &&
      fiveReplacementOkay
    ) {
      resolvesToIds.push(candidate.resolvesToID);

      return updateHarmonyStateChords({
        chordsToAddAndReplace: [
          { chordToAdd: two, chordToReplace: chordTwoReplaces ?? null },
          { chordToAdd: five, chordToReplace: chordFiveReplaces ?? null },
        ],
        harmonyState,
        settings,
      });
    }
  }

  return harmonyState;
}

function tryDependentChord({
  chordType,
  harmonyState,
  settings,
}: {
  chordType: AlternateChordSetType;
  harmonyState: HarmonyState;
  settings: LoopSettings;
}): HarmonyState {
  const { chords, baseChords, dependentChords, resolvesToIds } = harmonyState;

  for (let i = 0; i < dependentChords[chordType].length; i++) {
    const candidate = dependentChords[chordType][i];

    // retrieve chord to replace
    let chordToReplace;
    for (let i2 = 0; i2 < chords.length; i2++) {
      let chord = chords[i2];
      if (
        chord.beatNumber == candidate.beatNumber &&
        chord.measureNumber == candidate.measureNumber
      ) {
        chordToReplace = chord;
      }
    }

    // check if resolvesToID is in activeIDs and NOT in resolvedToIDs (i.e. another chord already resolves there)...
    // also make sure this chord will not replace a chord in resolvedToIDs and it is replacing a base chord
    if (
      chords.map((chord) => chord.ID).includes(candidate.resolvesToID) &&
      !resolvesToIds.includes(candidate.resolvesToID)
    ) {
      if (chordToReplace != null) {
        if (
          !resolvesToIds.includes(chordToReplace.ID) &&
          baseChords
            .map((baseChord) => baseChord.ID)
            .includes(chordToReplace.ID)
        ) {
          resolvesToIds.push(candidate.resolvesToID);

          return updateHarmonyStateChords({
            chordsToAddAndReplace: [{ chordToAdd: candidate, chordToReplace }],
            harmonyState,
            settings,
          });
        }
      } else {
        resolvesToIds.push(candidate.resolvesToID);

        return updateHarmonyStateChords({
          chordsToAddAndReplace: [
            { chordToAdd: candidate, chordToReplace: null },
          ],
          harmonyState,
          settings,
        });
      }
    }
  }

  return harmonyState;
}

function tryModalInterchange({
  harmonyState,
  modalInterchange,
  settings,
}: {
  harmonyState: HarmonyState;
  modalInterchange: ModalInterchange[];
  settings: LoopSettings;
}): HarmonyState {
  const { chords, resolvesToIds, baseChords } = harmonyState;

  for (let i = 0; i < modalInterchange.length; i++) {
    let candidate = modalInterchange[i];

    // retrieve chord to replace
    let chordToReplace;
    for (let i2 = 0; i2 < chords.length; i2++) {
      let chord = chords[i2];
      if (
        chord.beatNumber == candidate.beatNumber &&
        chord.measureNumber == candidate.measureNumber
      ) {
        chordToReplace = chord;
      }
    }

    if (chordToReplace == null) {
      throw new Error("no chord to replace found");
    }

    // check if chord being replaced is NOT in resolvedToIDs and is a base chord
    if (
      !resolvesToIds.includes(chordToReplace.ID) &&
      baseChords.map((baseChord) => baseChord.ID).includes(chordToReplace.ID)
    ) {
      // if satisfied => add chord to chords & harmony & bass notes & activeIDs, remove chord to be replaced in chords & harmonyNotes & bassNotes & activeIDs, update lengths of all chords

      return updateHarmonyStateChords({
        chordsToAddAndReplace: [{ chordToAdd: candidate, chordToReplace }],
        harmonyState,
        settings,
      });
    }
  }

  return harmonyState;
}

type ChordToAddWithOptionalReplace = {
  chordToAdd: Chord | null;
  chordToReplace: Chord | null;
};

function updateHarmonyStateChords({
  chordsToAddAndReplace,
  harmonyState,
  settings,
}: {
  chordsToAddAndReplace: ChordToAddWithOptionalReplace[];
  harmonyState: HarmonyState;
  settings: LoopSettings;
}) {
  const { voicingComplexity, closedOrOpen } = settings;

  let harmonyNotes: HarmonyNote[] = JSON.parse(
    JSON.stringify(harmonyState.harmonyNotes)
  );
  let bassNotes: HarmonyNote[] = JSON.parse(
    JSON.stringify(harmonyState.bassNotes)
  );
  let chords: HarmonyStateChord[] = JSON.parse(
    JSON.stringify(harmonyState.chords)
  );

  for (const chordSet of chordsToAddAndReplace) {
    let newChord = chordSet.chordToAdd;
    let chordToReplace = chordSet.chordToReplace;

    harmonyNotes = harmonyNotes.filter(
      (note) =>
        note.measureNumber != chordToReplace?.measureNumber ||
        note.deltaFromMeasure != chordToReplace?.beatNumber
    );

    if (newChord != null) {
      for (let i2 = 0; i2 < newChord.harmonyNotes.length; i2++) {
        let note = newChord.harmonyNotes[i2];
        if (
          note.complexity == voicingComplexity &&
          note.closedOrOpen == closedOrOpen
        ) {
          harmonyNotes.push(...note.notes);
        }
      }
    }

    bassNotes = bassNotes.filter(
      (note) =>
        note.measureNumber != chordToReplace?.measureNumber ||
        note.deltaFromMeasure != chordToReplace?.beatNumber
    );

    if (newChord != null) {
      bassNotes.push(newChord.bassNote);
    }

    chords = chords.filter(
      (chord) =>
        chord.measureNumber != chordToReplace?.measureNumber ||
        chord.beatNumber != chordToReplace?.beatNumber
    );

    if (!!newChord) {
      chords.push(newChord);
    }

    chords.sort(function (a, b) {
      if (a.measureNumber < b.measureNumber) {
        return -1;
      } else if (a.measureNumber > b.measureNumber) {
        return 1;
      } else if (a.beatNumber < b.beatNumber) {
        return -1;
      } else {
        return 1;
      }
    });
  }

  updateLengthOfChords({ chords, harmonyNotes, bassNotes });

  return {
    ...harmonyState,
    harmonyNotes: harmonyNotes,
    bassNotes: bassNotes,
    chords: chords,
  };
}

export function removeAlternateChords({
  chordsToRemove,
  harmonyState,
  settings,
}: {
  chordsToRemove: AlternateChordType[];
  harmonyState: HarmonyState;
  settings: LoopSettings;
}) {
  const { voicingComplexity, closedOrOpen } = settings;
  const { resolvesToIds } = harmonyState;
  const allChords: ChordToAddWithOptionalReplace[] = [];

  for (let i = 0; i < chordsToRemove.length; i++) {
    const chordTypeToRemove = chordsToRemove[i];

    const chordToRemove = harmonyState.chords.find(
      (chord) =>
        (chord as DependentChordSet).chordType != null &&
        (chord as DependentChordSet).chordType.toLowerCase() ==
          chordTypeToRemove.toLowerCase()
    ) as DependentChordSet;

    if (!chordToRemove || chordToRemove.resolvesToID == null) {
      return harmonyState;
    }

    resolvesToIds.splice(resolvesToIds.indexOf(chordToRemove.resolvesToID), 1);

    let baseChordToAdd = null;
    for (const baseChord of harmonyState.baseChords) {
      if (
        baseChord.beatNumber == chordToRemove.beatNumber &&
        baseChord.measureNumber == chordToRemove.measureNumber
      ) {
        baseChordToAdd = baseChord;
      }
    }

    allChords.push({
      chordToAdd: baseChordToAdd,
      chordToReplace: chordToRemove,
    });
  }

  return updateHarmonyStateChords({
    chordsToAddAndReplace: allChords,
    harmonyState,
    settings,
  });
}

function updateLengthOfChords({
  chords,
  harmonyNotes,
  bassNotes,
}: {
  chords: Chord[];
  harmonyNotes: HarmonyNote[];
  bassNotes: HarmonyNote[];
}) {
  for (let i = 0; i < chords.length; i++) {
    let prevChord = chords[i];
    let nextChord = chords[(i + 1) % chords.length];
    if (prevChord.measureNumber == nextChord.measureNumber) {
      prevChord.lengthInBeats = (nextChord.beatNumber -
        prevChord.beatNumber) as NoteLength;
    } else {
      prevChord.lengthInBeats = (4 - prevChord.beatNumber) as NoteLength;
    }
  }

  harmonyNotes.forEach((note) => {
    note.length = chords.filter(
      (chord) =>
        chord.measureNumber == note.measureNumber &&
        chord.beatNumber == note.deltaFromMeasure
    )[0].lengthInBeats;
  });
  bassNotes.forEach((note) => {
    note.length = chords.filter(
      (chord) =>
        chord.measureNumber == note.measureNumber &&
        chord.beatNumber == note.deltaFromMeasure
    )[0].lengthInBeats;
  });
}
