import { useCallback, useEffect, useRef } from "react";
import {
  ActiveHarmony,
  Chord,
  HarmonyNote,
  HarmonyState,
  NoteWithOctave,
} from "../types";
import * as Tone from "tone";

export const LENGTH_MAP = {
  1: "4n",
  2: "2n",
  3: "2n.",
  4: "1m",
};

const LOOP_TIME = "4n";

// Tone.getTransport().debug = true; // Uncomment to see transport events in console. damages perf

function getNextQNumber({ q, barsInLoop }: { q: number; barsInLoop: number }) {
  return (q + 1) % (barsInLoop * 4);
}

export function isChordOnQ({
  q,
  harmonyNotes,
}: {
  q: number;
  harmonyNotes: HarmonyNote[];
}) {
  for (const note of harmonyNotes) {
    if (q == note.measureNumber * 4 + note.deltaFromMeasure) {
      return true;
    }
  }
  return false;
}

export function getChordOnQOrThrow({
  q,
  chords,
}: {
  q: number;
  chords: Chord[];
}): Chord {
  const chord =
    chords.find(
      (chord) =>
        chord.measureNumber === Math.floor(q / 4) && chord.beatNumber === q % 4
    ) ?? null;

  if (chord == null) {
    console.log(q, chords);
    throw new Error();
  }
  return chord;
}

export function useTimeLoop({
  harmonyNotes,
  playDrums,
  barsInLoop,
  applyChordAtQ,
  activeHarmony,
  pressedNotes,
  harmonyState,
  drumQs,
}: {
  harmonyNotes: HarmonyNote[];
  playDrums: ({ time }: { time: Tone.Unit.Time }) => void;
  barsInLoop: number;
  applyChordAtQ: ({
    q,
    time,
    activeHarmony,
    pressedNotes,
    harmonyState,
  }: {
    q: number;
    time: Tone.Unit.Time;
    activeHarmony: ActiveHarmony;
    pressedNotes: NoteWithOctave[];
    harmonyState: HarmonyState;
  }) => void;
  activeHarmony: ActiveHarmony;
  pressedNotes: NoteWithOctave[];
  harmonyState: HarmonyState;
  drumQs: number[];
}) {
  // console.log("loop", pressedNotes);
  // console.log("time loop");
  const qNumberRef = useRef(-1);
  const loopRef = useRef<Tone.Loop | null>(null);
  const isPlayingRef = useRef(false);
  const pressedNotesRef = useRef(pressedNotes);
  const harmonyStateRef = useRef(harmonyState);
  const barsInLoopRef = useRef(barsInLoop);
  useEffect(() => {
    pressedNotesRef.current = pressedNotes;
  }, [pressedNotes]);
  const activeHarmonyRef = useRef(activeHarmony);
  useEffect(() => {
    activeHarmonyRef.current = activeHarmony;
  }, [activeHarmony]);
  useEffect(() => {
    harmonyStateRef.current = harmonyState;
  }, [harmonyState]);
  useEffect(() => {
    barsInLoopRef.current = barsInLoop;
  }, [barsInLoop]);

  const startTimeLoop = useCallback(() => {
    if (isPlayingRef.current) return;

    isPlayingRef.current = true;
    Tone.start();

    if (!loopRef.current) {
      loopRef.current = new Tone.Loop((time) => {
        qNumberRef.current = getNextQNumber({
          q: qNumberRef.current,
          barsInLoop: barsInLoopRef.current,
        });

        if (drumQs.includes(qNumberRef.current)) {
          playDrums({ time });
        }

        if (
          isChordOnQ({
            q: qNumberRef.current,
            harmonyNotes: harmonyStateRef.current.harmonyNotes,
          })
        ) {
          applyChordAtQ({
            q: qNumberRef.current,
            time,
            activeHarmony: activeHarmonyRef.current,
            pressedNotes: pressedNotesRef.current,
            harmonyState: harmonyStateRef.current,
          });
        }
      }, LOOP_TIME);
    }

    qNumberRef.current = -1;

    loopRef.current.start(0);
    Tone.getTransport().start();
  }, [applyChordAtQ, playDrums, drumQs]);

  const endTimeLoop = useCallback(() => {
    if (!isPlayingRef.current) return;

    isPlayingRef.current = false;
    if (loopRef.current) {
      loopRef.current.stop();
    }
    Tone.getTransport().stop();
  }, []);

  useEffect(() => {
    return () => {
      if (loopRef.current) {
        loopRef.current.dispose();
      }
      Tone.Transport.stop();
      Tone.Transport.cancel();
    };
  }, []);

  return {
    startTimeLoop,
    endTimeLoop,
  };
}
