import { IoReloadCircleOutline } from "react-icons/io5";
import { LoopSettings } from "../types";
import { UserInputChord } from "./NewChords";
import { ClipLoader } from "react-spinners";
import { PRIMARY_COLOR } from "../data/colors";
import { randomizeLowLevelSettings } from "../utils/settings";

export function NewChordsButton({
  loading,
  userInputChords,
  logEvent,
  stopLoop,
  settings,
  setSettings,
  fetchNewLoop,
}: {
  loading: boolean;
  userInputChords: UserInputChord[];
  logEvent: ({
    eventName,
    details,
  }: {
    eventName: string;
    details: any;
  }) => void;
  stopLoop: () => void;
  settings: LoopSettings;
  setSettings: (settings: LoopSettings) => void;
  fetchNewLoop: (settings: LoopSettings) => void;
}) {
  return (
    <div style={{ width: 80 }} className="joyride-new-chords">
      {!loading && (
        <div
          className="normal clickable icon"
          onClick={() => {
            logEvent({
              eventName: "new chords",
              details: {
                inputChords: JSON.stringify(
                  userInputChords.filter((chord) => chord.active)
                ),
              },
            });
            stopLoop();

            const newSettings = {
              ...settings,
              ...randomizeLowLevelSettings({
                complexity: Number(settings.voicingComplexity),
                settings: settings,
              }),
              numberOfBars: settings.numberOfBars,
            };

            setSettings(newSettings);
            fetchNewLoop(newSettings);
          }}
        >
          <IoReloadCircleOutline size={80} color={PRIMARY_COLOR} />
        </div>
      )}
      <ClipLoader size={60} color="white" loading={loading} />
    </div>
  );
}
