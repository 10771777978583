import { IoDownloadOutline } from "react-icons/io5";
import { PRIMARY_COLOR } from "../data/colors";

export function DownloadChordsButton({
  chordsMidiFilePath,
  logEvent,
}: {
  chordsMidiFilePath: string;
  logEvent: ({ eventName }: { eventName: string }) => void;
}) {
  return (
    <a
      className="anchor joyride-download-chords"
      href={chordsMidiFilePath}
      download="chords.mid"
      onClick={() =>
        logEvent({
          eventName: "download chords",
        })
      }
    >
      <div
        className="icon"
        style={{
          cursor: "pointer",
        }}
      >
        <IoDownloadOutline size={75} color={PRIMARY_COLOR} />
      </div>
    </a>
  );
}
