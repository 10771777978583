import { LoopSettings } from "../types";

export function randomizeLowLevelSettings({
  complexity,
  settings,
}: {
  complexity: number;
  settings: LoopSettings;
}) {
  // complexity = 0, 1, 2, 3, 4
  const sliderCenter = complexity;
  const switchProb = complexity / 5 + 0.05;

  const newSettings: LoopSettings = { ...settings };

  newSettings.voicingComplexity = getProbabilisticSliderValue({
    center: sliderCenter,
  });
  newSettings.passingChordFrequency = getProbabilisticSliderValue({
    center: 0,
  });
  newSettings.closedOrOpen = "closed";

  newSettings.minorTwoFive = getProbabilisticSwitchValue({
    prob: Math.max(switchProb - 0.3, 0),
  });
  newSettings.diminished = getProbabilisticSwitchValue({ prob: 0.25 });
  newSettings.modalInterchange = getProbabilisticSwitchValue({
    prob: Math.max(switchProb - 0.3, 0),
  });
  newSettings.primaryDominant = getProbabilisticSwitchValue({ prob: 0.3 });
  newSettings.secondaryDominant = getProbabilisticSwitchValue({
    prob: switchProb - 0.4,
  });
  newSettings.substituteDominant = getProbabilisticSwitchValue({
    prob: Math.max(switchProb - 0.4, 0),
  });

  return newSettings;
}

function getProbabilisticSwitchValue({ prob }: { prob: number }) {
  return Math.random() < prob;
}

function getProbabilisticSliderValue({ center }: { center: number }) {
  const probs = [
    [0.7, 0.22, 0.08, 0, 0], // must sum to 1 or null may be returned
    [0.175, 0.6, 0.175, 0.05, 0],
    [0.05, 0.15, 0.6, 0.15, 0.05],
    [0, 0.05, 0.175, 0.6, 0.175],
    [0, 0, 0.08, 0.32, 0.6],
  ];

  const vals = probs[center];

  let probSoFar = 1;

  for (let i = 0; i < vals.length; i++) {
    let val = vals[i];
    if (Math.random() < val / probSoFar) {
      return i;
    }
    probSoFar -= val;
  }

  throw new Error("unable to generate probabilistic slider value");
}
